function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function randomizeDots(circles) {
    const shuffled = circles.sort(() => 0.5 - Math.random());
    const turnOn = shuffled.slice(0, 8);
    const turnOff = [...document.querySelectorAll(".map-pt svg .circle.visible")];
    const toggle = [...turnOn, ...turnOff].sort(() => 0.5 - Math.random());
    toggle.forEach((el) => {
        setTimeout(() => {
            el.classList.toggle("visible");
        }, getRandomArbitrary(0, 10) * 500);
    });
    /*const toggle = [...turnOn, ...turnOff];
    toggle.forEach((el) => {
        el.classList.toggle("visible");
    });*/
}

(() => {
    const container = document.querySelector(".map-pt");
    if (!container) {
        return;
    }

    const circles = [...document.querySelectorAll(".map-pt svg .circle")];
    randomizeDots(circles);
    setInterval(randomizeDots.bind(null, circles),5000);

})()
