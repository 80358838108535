import * as bootstrap from "bootstrap";

(() => {

    const container = document.querySelector(".contact-form");
    if (!container) {
        return;
    }

    // Contact Form
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation")
    Array.from(forms).forEach(form => {
        form.addEventListener("submit", event => {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
            }
            form.classList.add("was-validated")
        }, false)
    });

    if (document.querySelector(".contact-form-section")) {
        const contactForm = {
            section: document.querySelector(".contact-form-section"),
            collapse: bootstrap.Collapse.getOrCreateInstance(".contact-form-section", {toggle: false}),
            form: document.querySelector(".contact-form-section > form"),
            status: {
                success: document.querySelector(".contact-form-section > .status > .success"),
                error: document.querySelector(".contact-form-section > .status > .error"),
                progress: document.querySelector(".contact-form-section > .status > .progressmsg")
            }
        }

        const formSumissionErrorHandlder = () => {
            contactForm.collapse.hide();
            contactForm.section.addEventListener("hidden.bs.collapse", () => {
                contactForm.status.progress.classList.add("d-none");
                contactForm.status.success.classList.add("d-none");
                contactForm.status.error.classList.remove("d-none");
                contactForm.form.classList.remove("d-none");
                contactForm.collapse.show();
            }, {once: true});

            container.closest(".alt-section")?.classList.add("error");
        }

        const formSumissionSuccessHandlder = () => {
            contactForm.collapse.hide();
            contactForm.section.addEventListener("hidden.bs.collapse", () => {
                contactForm.status.progress.classList.add("d-none");
                contactForm.status.success.classList.remove("d-none");
                contactForm.status.error.classList.add("d-none");
                contactForm.form.classList.add("d-none");
                contactForm.collapse.show();
            }, {once: true});

            container.closest(".alt-section")?.classList.add("success");
        }

        contactForm.form?.addEventListener("submit", event => {
            event.preventDefault()
            event.stopPropagation()
            if (!event.target.checkValidity()) {
                return;
            }
            const data = new FormData(event.target);
            const url = event.target.action;

            contactForm.collapse.hide();
            contactForm.section.addEventListener("hidden.bs.collapse", () => {
                // Reset the colors
                container.closest(".alt-section")?.classList.remove("error", "success");

                // Hide the section, hide form, reset status, add progress message
                contactForm.form.classList.add("d-none");
                contactForm.status.error.classList.add("d-none");
                contactForm.status.success.classList.add("d-none");
                contactForm.status.progress.classList.remove("d-none");
                // Show the section again
                contactForm.collapse.show();
                contactForm.section.addEventListener("shown.bs.collapse", () => {
                    // Submit the form
                    fetch(url, {
                        body: data,
                        method: "post"
                    }).then(response => {
                        response.text().then(value => {
                            if (value === "1") {
                                formSumissionSuccessHandlder();
                                return;
                            }
                            formSumissionErrorHandlder();
                        })
                    }).catch(formSumissionErrorHandlder);
                }, {once: true});
            }, {once: true});
        });
    }

})()
