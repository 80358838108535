import * as bootstrap from "bootstrap"
import "../blocks/blocks.script.js"

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

(() => {
    "use strict"

    // Random delay on arrows
    const arrows = document.querySelectorAll(".arrowx");
    [].forEach.call(arrows, (el) => {
        const random = getRandomArbitrary(0, 3);
        el.style.setProperty('--animation-state', 'paused');
        setTimeout(() => {
            el.style.setProperty('--animation-state', 'running');
        }, random * 1000);
    });

})()
