function activateTarget(main, info, target) {
    // Rotate
    const deg = target.dataset.rotation;
    main.style.transform = "rotate(0)";
    main.style.transform = "rotate(" + deg + "deg)";

    // Toggle Div
    info.style.height = 0;
    let targetHeight = 0;
    setTimeout(function() {
        for (const item of info.children) {
            item.style.display = "none";
            if (item.className === target.className) {
                item.style.display = "block";
                targetHeight = item.getBoundingClientRect().height;
            }
        }
        info.style.height = (targetHeight+20) + 'px';

        // Scroll into view
        setTimeout(() => {
            target.scrollIntoView(false);
        }, 650);

    }, 500);
}

(() => {

    const container = document.querySelector(".circle-solutions");
    if (!container) {
        return;
    }

    const main = document.querySelector("div.pcircle");
    const sections = main.children;
    const info = document.querySelector("div.pcircle-info");

    for (const section of sections) {
        section.addEventListener('click', (e) => {
            activateTarget(main, info, e.target);
        });
    }

})()
