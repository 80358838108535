function setRandomClass(tags) {
    let random = Math.floor((Math.random() * tags.length));
    [].forEach.call(tags, (el) => {
        el.classList.remove("active");
    });
    tags[random].classList.add("active");
}

(() => {

    const container = document.querySelector(".tag-cloud");
    if (!container) {
        return;
    }

    const tags = container.querySelectorAll(".wrapper-desktop .tag");
    setRandomClass(tags);
    setInterval(() => setRandomClass(tags), 1500);

})()
